var allQuotes = [
    {
        "q": "Gardening requires lots of water most of it in the form of perspiration.",
        "p": "Lou Erickson"
    },
    {
        "q": "You grow, girl."
    },
    {
        "q": "Odd as I am sure it will appear to some, I can think of no better form of personal involvement in the cure of the environment than that of gardening. A person who is growing a garden, if he is growing it organically, is improving a piece of the world. He is producing something to eat, which makes him somewhat independent of the grocery business, but he is also enlarging, for himself, the meaning of food and the pleasure of eating.",
        "p": "Wendell Berry"
    },
    {
        "q": "If you have a garden and a library, you have everything you need.",
        "p": "Marcus Tullius Cicero"
    },
    {
        "q": "Gardens are not made by singing ‘Oh, how beautiful,’ and sitting in the shade.",
        "p": "Rudyard Kipling"
    },
    {
        "q": "I’ll be planted right here."
    },
    {
        "q": "If I had a flower for every time I thought of you… I could walk through my garden forever.",
        "p": "Alfred Lord Tennyson"
    },
    {
        "q": "She is like a wildflower; beautiful, fierce, and free."
    },
    {
        "q": "There can be no other occupation like gardening in which if you were to creep up behind someone at their work, you would find them smiling.",
        "p": "Mirabel Osler"
    },
    {
        "q": "Happiness blooms from within."
    },
    {
        "q": "If you wish to make anything grow, you must understand it, and understand it in a very real sense. ‘Green fingers’ are a fact, and a mystery only to the unpracticed. But green fingers are the extensions of a verdant heart.",
        "p": "Russell Page"
    },
    {
        "q": "You’re only here for a short visit. Don’t hurry, don’t worry. And be sure to smell the flowers along the way.",
        "p": "Walter Hagen"
    },
    {
        "q": "To plant a garden is to believe in tomorrow.",
        "p": "Audrey Hepburn"
    },
    {
        "q": "Despite the gardener’s best intentions, Nature will improvise.",
        "p": "Michael Garafalo"
    },
    {
        "q": "The closest I am to being a parent is taking care of all of my plants."
    },
    {
        "q": "I grow plants for many reasons: to please my eye or to please my soul, to challenge the elements or to challenge my patience, for novelty or for nostalgia, but mostly for the joy in seeing them grow.",
        "p": "David Hobson"
    },
    {
        "q": "The love of gardening is a seed once sown that never dies.",
        "p": "Gertrude Jekyll"
    },
    {
        "q": "Trees and plants always look like the people they live with, somehow.",
        "p": "Zora Neale Hurston"
    },
    {
        "q": "I love spring anywhere, but if I could choose I would always greet it in a garden.",
        "p": "Ruth Stout"
    },
    {
        "q": "You can’t buy happiness, but you can buy plants, and that’s pretty much the same thing."
    },
    {
        "q": "Half the interest of a garden is the constant exercise of the imagination.",
        "p": "Mrs. C.W. Earle, Pot-pourri from a Surrey Garden, 1897"
    },
    {
        "q": "I’m rooting for my flowers to grow."
    },
    {
        "q": "The single greatest lesson the garden teaches is that our relationship to the planet need not be zero-sum and that as long as the sun still shines and people still can plan and plant, think and do, we can if we bother to try, find ways to provide for ourselves without diminishing the world.",
        "p": "Michael Pollan"
    },
    {
        "q": "God Almighty first planted a garden. And indeed, it is the purest of human pleasures.",
        "p": "Francis Bacon"
    },
    {
        "q": "Plants give us oxygen for the lungs and for the soul.",
        "p": "Linda Solegato"
    },
    {
        "q": "Advice from a sunflower: be bright, sunny, and positive. Spread seeds of happiness. Rise, shine, and hold your head high."
    },
    {
        "q": "Remember that children, marriages, and flower gardens reflect the kind of care they get.",
        "p": "H. Jackson Brown, Jr."
    },
    {
        "q": "When in doubt, add flowers."
    },
    {
        "q": "Garden as though you will live forever.",
        "p": "William Kent"
    },
    {
        "q": "The greatest fine art of the future will be the making of a comfortable living from a small piece of land.",
        "p": "Abraham Lincoln"
    },
    {
        "q": "Turn up the sunshine and flowers, please."
    },
    {
        "q": "Gardening is about enjoying the smell of things growing in the soil, getting dirty without feeling guilty, and generally taking the time to soak up a little peace and serenity.",
        "p": "Lindley Karstens"
    },
    {
        "q": "Like wildflowers; you must allow yourself to grow in all the places people thought you never would.”",
        "p": "E.V."
    },
    {
        "q": "If a tree dies, plant another in its place.",
        "p": "Carl Linnaeus"
    },
    {
        "q": "Plants cry their gratitude for the sun in green joy.",
        "p": "Astrid Alauda"
    },
    {
        "q": "Let no one think that real gardening is a bucolic and meditative occupation. It is an insatiable passion, like everything else to which a man gives his heart",
        "p": "Karel Capek, The Gardener’s Year, 1931"
    },
    {
        "q": "The glory of gardening: hands in the dirt, head in the sun, heart with nature. To nurture a garden is to feed not just the body, but the soul.",
        "p": "Alfred Austin"
    },
    {
        "q": "The Earth laughs in flowers.",
        "p": "Ralph Waldo Emerson"
    },
    {
        "q": "Everything that slows us down and forces patience, everything that sets us back into the slow circles of nature, is a help. Gardening is an instrument of grace.",
        "p": "May Sarton"
    },
    {
        "q": "There are no gardening mistakes, only experiments.",
        "p": "Janet Kilburn Phillips"
    },
    {
        "q": "Where flowers bloom so does hope.",
        "p": "Lady Bird Johnson"
    },
    {
        "q": "I like gardening, it’s a place where I find myself when I need to lose myself.",
        "p": "Alice Sebold"
    },
    {
        "q": "I must have flowers, always and always.",
        "p": "Claude Monet"
    },
    {
        "q": "The garden suggests there might be a place where we can meet nature halfway.",
        "p": "Michael Pollan"
    },
    {
        "q": "Plants can’t solve all problems, but they’re a great start."
    },
    {
        "q": "When the world wearies, and society fails to satisfy, there is always the garden.",
        "p": "Minnie Aumonier"
    },
    {
        "q": "A garden is a grand teacher. It teaches patience and careful watchfulness; it teaches industry and thrift; above all, it teaches entire trust.",
        "p": "Gertrude Jekyll"
    },
    {
        "q": "There are always flowers for those who want to see them.",
        "p": "Henri Matisse"
    },
    {
        "q": "A society grows great when old men plant trees whose shade they know they shall never sit in.",
        "p": "Greek proverb"
    },
    {
        "q": "If you look the right way, you can see that the whole world is a garden.",
        "p": "Frances Hodgson Burnett"
    }
];